import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import './CookieConsent.css'; // Import the CSS file for styles

const cookies = new Cookies();

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!cookies.get('cookieConsent')) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    cookies.set('cookieConsent', 'true', { path: '/', maxAge: 60 * 60 * 24 * 365 });
    setShowBanner(false);
  };

  const handleAcceptEssential = () => {
    cookies.set('cookieConsent', 'essential', { path: '/', maxAge: 60 * 60 * 24 * 365 });
    setShowBanner(false);
  };

  // const handleDeclineAll = () => {
  //   cookies.set('cookieConsent', 'declined', { path: '/', maxAge: 60 * 60 * 24 * 365 });
  //   setShowBanner(false);
  // };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner">
          <p className="cookie-text">
            This site uses cookies to offer you a better browsing experience. Find out more on &nbsp;
            <a href="https://www.phoenix.ai/privacy-policy" className="cookie-link">How we use cookies </a>&nbsp;, &nbsp;
            <a href="https://www.phoenix.ai/TermsOfUse" className="cookie-link">Terms of use</a>.
          </p>
          &nbsp;&nbsp;
          <button onClick={handleAcceptAll} className="cookie-button">Accept all cookies</button>
          <button onClick={handleAcceptEssential} className="cookie-button">Accept only essential cookies</button>
          {/* <button onClick={handleDeclineAll} className="cookie-button">Decline all</button> */}
        </div>
      )}
    </>
  );
};

export default CookieConsent;