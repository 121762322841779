// card.js
import React, { useState } from 'react';
import { Row, Col, Container, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Solutions.css';
import { Helmet } from 'react-helmet';
import CardComponent from './CardComponent'; // Import the new CardComponent
import Solutions_Data from './Solutions_Data.json'; // Import the news data

const ThreeColumnCards = () => {
  const cardsPerPage = 6; // Set to 9 to have 3 rows of 3 cards each
  const [currentPage, setCurrentPage] = useState(1); // Current active page number

  // Card data array
    const cardInfo = Solutions_Data;
    // ... Your card data
    // Your card objects here


  // Calculate the number of pages needed
  const pageCount = Math.ceil(cardInfo.length / cardsPerPage);

  // Get current cards for the page
  const currentCards = cardInfo.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  // Function to handle page change
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create pagination items
  let paginationItems = [];
  for (let number = 1; number <= pageCount; number++) {
    paginationItems.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageClick(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <Helmet>
        <title>About Us - Empowering Underprivileged with AI</title>
        <meta name="description" content="Empowering underprivileged children with AI to provide equal opportunities in education and skill development." />
        <meta name="keywords" content="AI education, underprivileged empowerment, educational equity, AI for good, inclusive technology" />

        <meta charset="utf-8" />
        <link rel="icon" href="https://www.phoenix.ai/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="About Us - Empowering Underprivileged with AI" />
        <meta property="og:description" content="Dedicated to leveling the educational playing field, our platform uses AI to enhance learning and development for underprivileged children, ensuring every child can thrive in the 21st century." />
        <meta property="og:image" content="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="apple-touch-icon" href="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      <Container>
        <div className="separator" /> {/* Custom separator */}
        <h1>Solutions</h1>
        <div className="separator" /> {/* Custom separator */}

        <Row>
          {currentCards.map((card, index) => (
            <Col lg={4} md={6} sm={12} className="mb-4 d-flex" key={index}>
              <CardComponent image={card.image} title={card.title} text={card.text} />
            </Col>
          ))}
        </Row>

        <Pagination className="justify-content-center my-pagination">{paginationItems}</Pagination>        <div className="separator" /> {/* Custom separator */}

      </Container>
    </>
  );
};

export default ThreeColumnCards;