// CardComponent.js

import React from 'react';
import { Card } from 'react-bootstrap';
import './News.css';

const CardComponent = ({ image, date, title, text, url, alt, topic }) => (
  <Card className="fixed-width-card">
    <div className="aspect-ratio-16-9">
      <Card.Img
        variant="top"
        src={image}
        className="card-img-top"
        alt={alt}
      />
    </div>
    <Card.Body>
      {date && <p className="card-date card-date-small">{date}</p>}

      <Card.Title className="custom-card-title">{title}</Card.Title>
      <hr className="custom-card-divider" />
      <Card.Text className="custom-card-text">{text}</Card.Text>
      {url && (
        <Card.Text className="custom-card-text">
          <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
        </Card.Text>
      )}
    </Card.Body>
    {/* Use Card.Footer to attach the date and topic to the bottom of the card */}
    <Card.Footer>
      {topic && <span className="card-topic">{topic}</span>}
      {/* {date && <span className="card-date card-date-small">{date}</span>} */}
    </Card.Footer>
  </Card>
);

export default CardComponent;