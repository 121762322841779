import React from 'react';
import { MapContainer, TileLayer, SVGOverlay } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import hongKongSVG from './hongkong.svg';

const HongKongMap = () => {
  // Define the geographical bounds of the SVG
  const bounds = [
    // [22.119327, 113.825900], 
    [20.119327, 100.825900], 

    // Southwest coordinates
    // [22.573610, 114.502460]
    [24.573610, 130.502460]  
    // Northeast coordinates
  ];

  return (
    <MapContainer center={[22.396428, 114.109497]} zoom={13} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* <SVGOverlay attributes={{ stroke: 'red' }} bounds={bounds}>
        <image href={hongKongSVG} x="0" y="0" width="100%" height="100%" />
      </SVGOverlay> */}
    </MapContainer>
  );
};

export default HongKongMap;