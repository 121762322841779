import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider.js';
import Solutions from '../Solutions/Solutions.jsx';
// import News from '../News/News.js';
// import CookieManager from '../CookieConsent/CookieConsent.jsx'
// import Marquee from '../Marquee/marquee.jsx';
// import SvgMapComponent from '../GBA/svgmap.jsx';
import Helmet from 'react-helmet';

const HomePage = () => {
  return (
    <>

      <Helmet>
        <title>About Us - Empowering Underprivileged with AI</title>
        <meta name="description" content="Empowering underprivileged children with AI to provide equal opportunities in education and skill development." />
        <meta name="keywords" content="AI education, underprivileged empowerment, educational equity, AI for good, inclusive technology" />

        <meta charset="utf-8" />
        <link rel="icon" href="https://www.phoenix.ai/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="About Us - Empowering Underprivileged with AI" />
        <meta property="og:description" content="Dedicated to leveling the educational playing field, our platform uses AI to enhance learning and development for underprivileged children, ensuring every child can thrive in the 21st century." />
        <meta property="og:image" content="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="apple-touch-icon" href="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      {/* <section id="home"> */}
      <HeroSlider />
      {/* </section> */}
      {/* <section id="solutions"> */}
      <Solutions />
      {/* <Marquee /> */}
      {/* <SvgMapComponent /> */}
      {/* </section> */}
      {/* <section id="news">
        <News />
      </section> */}
      {/* <section id="aboutus">
        <AboutUs />
      </section> */}
      {/* <CookieManager /> */}

    </>
  );
};

export default HomePage;