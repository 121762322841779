// CarouselComponent.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarouselComponent.css'; // Import your custom CSS file

const HeroSlider = () => {
  return (
    <Carousel data-bs-interval="10000"> {/* Adjust the interval to a higher number like 10000 (10 seconds) */}
      <Carousel.Item style={{ backgroundImage: "url('mbr-1.jpg')" }}>
        <Carousel.Caption>
          <h1>Empowering Underprivileged with A.I.</h1>
          {/* <p>Step into the future with Hong Kong as it leverages Artificial Intelligence to foster a more vibrant and efficient environment, dedicated to improving the quality of life for all residents, with the aim of benefiting society.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ backgroundImage: "url('mbr-14.jpg')" }}>
        <Carousel.Caption>
          <h1>Harnessing the Power of A.I. to Break the Cycle of Poverty</h1>
          {/* <p>Leveraging technology to enhance quality of life, urban efficiency, and competitiveness</p> */}
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item style={{ backgroundImage: "url('mbr-15.jpg')" }}>
        <Carousel.Caption>
          <h1>Building Brighter Futures Through A.I. Empowerment</h1>
          {/* <p>Step into the future with Hong Kong as it leverages Artificial Intelligence to foster a more vibrant and efficient environment, dedicated to improving the quality of life for all residents, with the aim of benefiting society.</p> */}
        </Carousel.Caption>
      </Carousel.Item>

      {/* Uncomment the third item if needed */}
      {/* <Carousel.Item style={{ backgroundImage: "url('developmentgleaming_server_1920x1080.jpg')" }}>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
};

export default HeroSlider;