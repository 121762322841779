// Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
// Make sure to import i18n.js somewhere in your app
import '../i18n';

const Header = () => {
  const navigate = useNavigate();
  // const { t, i18n } = useTranslation();
  const { t} = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  const handleNavClick = (event, path, sectionId) => {
    event.preventDefault(); // Prevent default link behavior
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        // If the section is present on the current page, scroll to it
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        // If the section is not found, navigate to the path
        navigate(path);

        // Optionally use a small delay before attempting to scroll to the section
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }
    } else {
      // If no sectionId is provided, navigate to the path
      navigate(path);
    }
  };
//
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand as={Link} to="/">PHOENIX.AI</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link onClick={(event) => handleNavClick(event, '/')}>{t('Home')}</Nav.Link>
            {/* <Nav.Link onClick={(event) => handleNavClick(event, '/Solutions')}>{t('Solutions')}</Nav.Link> */}
            {/* <Nav.Link onClick={(event) => handleNavClick(event, '/resources')}>{t('Resources')}</Nav.Link> */}
            <Nav.Link onClick={(event) => handleNavClick(event, '/aboutus')}>{t('About Us')}</Nav.Link>
            {/* <Nav.Link onClick={() => changeLanguage('en')}>EN</Nav.Link>
            <Nav.Link onClick={() => changeLanguage('zh-TW')}>中文</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;