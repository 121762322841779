import React from 'react';
import HeroSlider from '../../../components/web/HeroSlider/HeroSlider';
// import Solutions from '../../components/web/Solutions/Solutions';
import News from './News.js';
// import AboutUs from '../../components/web/AboutUs/AboutUs';

const HomePage = () => {
  return (
    <>
      <section id="home">
        <HeroSlider />
      </section>

      <section id="news">
        <News />
      </section>

    </>
  );
};

export default HomePage;