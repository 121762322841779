//aboutus.js

import React from 'react';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './About_us.css';
import Helmet from 'react-helmet';

const Aboutus = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Empowering Underprivileged with AI</title>
        <meta name="description" content="Empowering underprivileged children with AI to provide equal opportunities in education and skill development." />
        <meta name="keywords" content="AI education, underprivileged empowerment, educational equity, AI for good, inclusive technology" />

        <meta charset="utf-8" />
        <link rel="icon" href="https://www.phoenix.ai/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="About Us - Empowering Underprivileged with AI" />
        <meta property="og:description" content="Dedicated to leveling the educational playing field, our platform uses AI to enhance learning and development for underprivileged children, ensuring every child can thrive in the 21st century." />
        <meta property="og:image" content="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="apple-touch-icon" href="https://www.phoenix.ai/mbr-1.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      <Container>
        <div className="separator" />

        <h1 className="text-left">About Us </h1>

        <div className="separator" />

        <Card className="fixed-width-card">
          <Card.Body>
            <Card.Title className="custom-card-title">Our mission</Card.Title>
            <Card.Text className="text-left-align">
              Empowering Underprivileged with A.I.

              At the heart of our mission, we belief that every child, irrespective of their socio-economic background, deserves access to quality education and the opportunity to reach their full potential. Understanding the unique challenges faced by these children, we have built a platform designed to break down barriers to education and cultivate essential skills needed for a brighter future.
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">Our platform</Card.Title>
            <Card.Text className="text-left-align">
              {/* Creating an AI Transformation Platform dedicated to improving people's lives and benefiting society involves developing a versatile ecosystem that enables the deployment of AI solutions across various domains. This platform would serve as a connecting hub for the technology, society and business. */}
              Harnesses the power of cutting-edge AI technology to offer three key features:
              <br></br>
              <br></br>

              1) Data Collection & Predictive Analytics
              <br></br>

              2) Personalized Experiences
              <br></br>

              3) AI-Powered Skill Development and Learning
              <br></br>
              <br></br>

              We believe in the potential of AI to revolutionize education and make a meaningful impact on children's lives. By empowering the underprivileged with AI, we strive to level the playing field, ensuring every child has an equal opportunity to thrive in the 21st century.

              We invite you to join us on this exciting journey, as we strive to rewrite the narrative for underprivileged children through the power of AI. Together, we can create a world where no child is left behind.
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">Email</Card.Title>
            <Card.Text className="text-left-align">
              <a  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                style={{ textDecoration: 'none' }}
                href="mailto: enquiry@phoenix.ai"
              >
                enquiry@phoenix.ai
              </a>
            </Card.Text>
          </Card.Body>
        </Card>

        <div className="separator" />
      </Container>
    </>
  );
};

export default Aboutus;