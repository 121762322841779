import React from "react";
import "./footer.css";
import { Helmet } from 'react-helmet'; // Import Helmet
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// import PrivacyModal from "../ModalView/PrivacyModal";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faYoutube,
//     faGithub,
//     faFacebook,
// } from "@fortawesome/free-brands-svg-icons";
// import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return (
        <>
            <Helmet>
                <title>About Us - Empowering Underprivileged with AI</title>
                <meta name="description" content="Empowering underprivileged children with AI to provide equal opportunities in education and skill development." />
                <meta name="keywords" content="AI education, underprivileged empowerment, educational equity, AI for good, inclusive technology" />

                <meta charset="utf-8" />
                <link rel="icon" href="https://www.phoenix.ai/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta property="og:title" content="About Us - Empowering Underprivileged with AI" />
                <meta property="og:description" content="Dedicated to leveling the educational playing field, our platform uses AI to enhance learning and development for underprivileged children, ensuring every child can thrive in the 21st century." />
                <meta property="og:image" content="https://www.phoenix.ai/mbr-1.jpg" />
                <link rel="apple-touch-icon" href="https://www.phoenix.ai/mbr-1.jpg" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
            </Helmet>

            <br></br>
            <div className="footer-container">
                <footer className="fixed-bottom">
                    <div className="footer item1">
                        {/* <PrivacyModal /> */}
                        {/* <Link to="/privacy-policy">Privacy Policy &nbsp; &nbsp; </Link>

                        <Link to="/TermsOfUse">&nbsp;&nbsp; Terms of use</Link> */}
                    </div>
                    <br></br>
                    <div className="footer item2">
                        <Link to="/privacy-policy">Privacy Policy&nbsp;&nbsp;</Link>
                        {/* <PrivacyModal /> */}
                        <Link to="/TermsOfUse">&nbsp;&nbsp;Terms of use</Link>
                    </div>
                    <br></br>
                    <div className="footer item2">
                        Copyright 2024 ©PHOENIX.AI All Rights Reserved.&nbsp;

                    </div>
                </footer>

            </div>

            <br></br>

            <footer className="footer">
                <div className="footer-container">
                    <div className="footer item1">
                        {/* <PrivacyModal /> */}
                        {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
                    </div>
                    <div className="footer item2">
                        {/* <span style={{ paddingRight: 5 }}> */}
                        {/* Copyright 2024 ©PHOENIX.AI All Rights Reserved. */}
                        {/* <FontAwesomeIcon icon={faCopyright} />{" "} */}
                        {/* {new Date().getFullYear()} */}



                        {/* </span> */}
                    </div>
                    {/* <a
                    href="https://github.com/sudiptob2/simple-react-footer"
                    target="_blank"
                    className="item3"
                >
                    <FontAwesomeIcon icon={faGithub} />
                </a> */}
                    {/* <a
                    href="http://fb.com/sudiptob2"
                    target="_blank"
                    className="item4"
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </a> */}
                    {/* <a
                    href="https://www.youtube.com/"
                    target="_blank"
                    className="item5"
                >
                    <FontAwesomeIcon icon={faYoutube} />
                </a> */}

                    {/* {false && <PrivacyModal click={true} />} */}
                </div>





            </footer>
        </>

    );
};

export default Footer;