import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import reportWebVitals from './reportWebVitals';

// Find the root element in your HTML
const container = document.getElementById('root');

// Create a root using the container
const root = createRoot(container);

// Render your app within the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// For performance measurement, you can pass a function to reportWebVitals
// reportWebVitals(console.log);
// Learn more about reportWebVitals in the included README.md file.
reportWebVitals();
