import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider';
// import Solutions from '../../components//Solutions/Solutions';
// import News from '../../components/News/News.js';
import Aboutus from '../../../components/web/Aboutus/Aboutus';

const AboutusPage = () => {
  return (
    <>
      <section id="home">
        <HeroSlider />
      </section>

      {/* <section id="news"> */}
        <Aboutus />
      {/* </section> */}

    </>
  );
};

export default AboutusPage;